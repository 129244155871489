import { type WebformElementTextBaseFragment } from '#graphql-operations'

export default function (prefills: InputPrefill[], formElement: WebformElementTextBaseFragment) {
  const prefill = computed(() => {
    const prefilledInput = prefills.find(input => input.key === formElement?.key)
    return prefilledInput ? decodeURI(prefilledInput.value) : ''
  })

  return {
    prefill
  }
}
